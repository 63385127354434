import { FC } from 'react'
import { SpeakerFeaturedFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { Sizes } from 'utility/Sizes'
import { SpeakerModal } from '../Speaker.Modal/Speaker.Modal'
import { useState } from 'react'
import { useSearchParams, useRouter, usePathname } from 'next/navigation'
import { produce } from 'immer'

import styles from './Speaker.Featured.module.scss'

export const SpeakerFeatured: FC<SpeakerFeaturedFragment> = ({
    ...speaker
}) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()
        const speakerParam = searchParams.get('speaker')
    
        const [ open, setOpen ] = useState<boolean>(speakerParam === `${speaker.forename}-${speaker.surname}`)

        const updateQuery = (
            value: string
        ): void => {
            const field = "speaker"
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                if ( value === 'all' || value === searchParams.get(field) ) {
                    delete draft[field]
                }
                else {
                    draft[field] = value
                }
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
            value === 'all' ? setOpen(false) : setOpen(true)
        }
    
    return (
        <>
            <SpeakerModal open={open} updateQuery={updateQuery} {...speaker} />
            <div className={styles.Speaker} onClick={ () => updateQuery(`${speaker.forename}-${speaker.surname}`) }>
                {speaker.photo ?
                    <ComponentImagePicture
                        {...speaker.photo}
                        className={styles.Image}
                        sizes={Sizes.Featured}
                    />
                    : <></>
                }
                <div className={styles.SpeakerText}>
                    <h2>{speaker.forename} {speaker.surname}</h2>
                    <h3>{speaker.jobTitle}</h3>
                    <p>{speaker.company?.data?.attributes?.title}</p>
                </div>
            </div>
        </>
    )
}
