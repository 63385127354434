'use client'

import { Suspense } from 'react'
import { BodyObjectView, key } from 'utility/utility'
import { ComponentBodySpeakerListFragment, Enum_Componentbodyspeakerlist_Type as listType } from 'generated/graphql'
import { useSearchParams } from 'next/navigation'

import { SpeakerFeatured } from 'content-types/Speaker/Speaker.Featured/Speaker.Featured'
import { Theme } from 'content-types/Theme/Theme'

import { Markdown } from 'components/shared/Markdown/Markdown'
import { SectionHeader } from 'components/shared/SectionHeader/SectionHeader'
import { EventFilters } from './EventFilters'

import styles from './ComponentBodySpeakerList.module.scss'

export const ComponentBodySpeakerList: BodyObjectView<ComponentBodySpeakerListFragment> =
    ({
        text,
        sectionBackground,
        theme,
        event,
        featuredSpeakers,
        siteContext,
        pageContext,
        articleContext,
        speakerListType,
        speakerHeaderAlign,
        useRelatedEvents,
        ...object
    }) => {

        const searchParams = useSearchParams()

        if (useRelatedEvents === null) useRelatedEvents = true

        const taggedSpeakers = event?.data?.attributes?.speakers?.data ?? []
        const parentEventSpeakers = useRelatedEvents ? event?.data?.attributes?.parent_event?.data?.attributes?.speakers?.data ?? [] : []
        const childEventsSpeakers = useRelatedEvents ? event?.data?.attributes?.child_events?.data?.map(event => event.attributes?.speakers?.data) ?? [] : []
        const siblingEventsSpeakers = useRelatedEvents ? event?.data?.attributes?.parent_event?.data?.attributes?.child_events?.data?.map(event => event.attributes?.speakers?.data) ?? [] : []

        const speakers = speakerListType === listType.FullBrowser ? [...new Set([
            ...taggedSpeakers.map( ({attributes}) => attributes ),
            ...parentEventSpeakers.map( ({attributes}) => attributes ),
            ...childEventsSpeakers.flat().map( speaker => speaker!.attributes ),
            ...siblingEventsSpeakers.flat().map( speaker => speaker!.attributes )
        ])].filter((speaker1, i, arr) =>
                arr.findIndex(speaker2 => (speaker2?.name === speaker1?.name)) === i //removing duplicates
            ) : featuredSpeakers.map(speaker => speaker.speaker.data?.attributes)

        const events = [...new Set(speakers.map(speaker => speaker?.events?.data?.map(event => event.attributes?.title)).flat())]

        const currentEvent = searchParams.get('event')

        return (
            <section
                id={key(object)}
                className={styles.SpeakerList}
                data-background={sectionBackground?.sectionBackground}
                data-header-align={speakerHeaderAlign}
                style={ Theme(theme?.data?.attributes) }
            >
                {
                    text === null || text === '' ? <></> :
                        <SectionHeader>
                            <Markdown
                                siteContext={siteContext}
                                pageContext={pageContext}
                                articleContext={articleContext}
                            >
                                {text}
                            </Markdown>
                        </SectionHeader>
                }
                {events.length > 1 ? <Suspense><EventFilters events={events} /></Suspense> : <></>}
                <ul data-number={speakers.length}>
                    {
                        speakers
                            .map(
                                speaker =>
                                      speaker === null ? <></>
                                    : speaker === undefined ? <></>
                                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                                    : currentEvent !== null && speaker.events?.data.map(event => event.attributes?.title).includes(currentEvent) ? <></>
                                    :   <li key={speaker.name}>
                                            <SpeakerFeatured
                                                {...speaker}
                                            />
                                        </li>
                            )
                    }
                </ul>
            </section>
        )

    }
