'use client'

import { Suspense } from 'react'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import { produce } from 'immer'
import { BodyObjectView, normaliseDate } from 'utility/utility'

import { EventSummaryFragment } from 'generated/graphql'

import { Filters } from 'components/shared/Filters/Filters'
import { EventTypeFilter } from 'components/filters/EventTypeFilter/EventTypeFilter'
import { EventLocationFilter } from 'components/filters/EventLocationFilter/EventLocationFilter'
import { PortfolioFilter } from 'components/filters/PortfolioFilter/PortfolioFilter'
import { TopicFilter } from 'components/filters/TopicFilter/TopicFilter'
import { TimeFilter } from 'components/filters/TimeFilter/TimeFilter'

import { EventSummary } from 'content-types/Event/Event.Summary/Event.Summary'
import { EventMetadata } from 'content-types/Event/Event.Metadata/Event.Metadata'

import styles from './ComponentBodyEventBrowser.module.scss'

export const EventFilterList: BodyObjectView<{
    events: readonly EventSummaryFragment[]
}> =
    ({
        events,
        siteContext,
        pageContext,
        articleContext
    }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const type      = searchParams.get('type')
        const location  = searchParams.get('location')
        const portfolio = searchParams.get('portfolio')
        const topic     = searchParams.get('topic')
        const time      = searchParams.get('time')

        const updateQuery = (updates: { [key: string]: string } ): void => {
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                Object.entries(updates).forEach( ([field,value]) => {
                    if ( value === 'all' ) {
                        delete draft[field]
                    }
                    else {
                        draft[field] = value
                    }
                })
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const setType = (type: string): void =>
            updateQuery({type})

        const setLocation = (location: string): void =>
            updateQuery({location})

        const setPortfolio = (portfolio: string): void => {
            updateQuery({
                portfolio,
                topic: 'all'
            })
        }

        const setTopic = (topic: string): void =>
            updateQuery({topic})

        const setTime = (time: string): void =>
            updateQuery({time})

        const now = normaliseDate(new Date())

        return (
            <>
                <Filters className={styles.Filters}>
                    <Suspense>
                        <EventTypeFilter
                            key='event_type'
                            value={type}
                            onChange={setType}
                            className={styles.TypeFilter}
                            objects={events}
                        />
                    </Suspense>
                    <Suspense>
                        <EventLocationFilter
                            key='location'
                            value={location}
                            onChange={setLocation}
                            className={styles.locationFilter}
                            events={events}
                        />
                    </Suspense>
                    <Suspense>
                        <PortfolioFilter
                            key='portfolio'
                            value={portfolio}
                            onChange={setPortfolio}
                            className={styles.PortfolioFilter}
                            objects={events}
                            expand={false}
                        />
                    </Suspense>
                    <Suspense>
                        <TopicFilter
                            key='topic'
                            value={topic}
                            onChange={setTopic}
                            portfolio={portfolio ?? ''}
                            className={styles.TopicFilter}
                            pageContext={pageContext}
                            objects={events}
                            expand={false}
                        />
                    </Suspense>
                    <Suspense>
                        <TimeFilter
                            key='time'
                            value={time}
                            onChange={setTime}
                            className={styles.TimeFilter}
                        />
                    </Suspense>
                </Filters>
                <ul key='events'>
                    {
                        events.map( event =>
                            <li
                                key={event.slug}
                                data-visible={
                                    (
                                        (
                                            ( time === null || time === 'upcoming' )
                                            && ( event.endDate === null || normaliseDate(new Date(event.endDate)) >= now )
                                        )
                                        ||
                                        (
                                            time === 'recent'
                                            && ( event.endDate === null || normaliseDate(new Date(event.endDate)) < now )
                                        )
                                    )
                                    &&
                                    (
                                           type === null
                                        || type === 'all'
                                        || event.eventType as string === type
                                    )
                                    &&
                                    (
                                           location === null
                                        || location === 'all'
                                        || event.venue?.country === location
                                    )
                                    &&
                                    (
                                           portfolio === null
                                        || portfolio === 'all'
                                        || event.portfolios?.data.find( p => p.attributes?.slug === portfolio ) !== undefined
                                    )
                                    &&
                                    (
                                           topic === null
                                        || topic === 'all'
                                        || event.topics?.data.find( t => t.attributes?.slug === topic ) !== undefined
                                    )
                                    &&
                                        (event.hidden === null
                                        || !event.hidden)
                                }
                            >
                                <EventSummary
                                    {...event}
                                    siteContext={siteContext}
                                    pageContext={pageContext}
                                    articleContext={articleContext}
                                />
                                <EventMetadata
                                    siteContext={siteContext}
                                    {...event}
                                />
                            </li>
                        )
                    }
                </ul>
            </>
        )

    }

