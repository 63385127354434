import { SpeakerModalFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { Sizes } from 'utility/Sizes'
import { Modal } from 'react-dialog-polyfill'
import buttonStyles from 'styles/button.module.scss'
import Link from 'next/link'
import { FC } from 'react'

import styles from './Speaker.Modal.module.scss'

export const SpeakerModal: FC<SpeakerModalFragment & {open: boolean, updateQuery: Function}> = ({
    photo,
    company,
    forename,
    surname,
    jobTitle, 
    biography,
    open,
    updateQuery,
}) => {

    return (
        <>
            <Modal
                id={`speaker${forename}${surname}`}
                className={styles.Modal}
                open={open}
            >
                <header>
                    <h1>{forename} {surname}</h1>
                    <button
                        onClick={ () => updateQuery("all") }
                        className={buttonStyles.Link}
                    >
                        Close
                    </button>
                </header>
                <div className={styles.ModalBody}>
                    <div className={styles.SpeakerImage}>
                        {photo ?
                            <ComponentImagePicture
                                {...photo}
                                className={styles.Image}
                                sizes={Sizes.Featured}
                            />
                            : <></>
                        }
                    </div>
                    <div className={styles.CompanyLogo}>
                        {company?.data?.attributes?.picture ?
                            <ComponentImagePicture
                                {...company.data.attributes.picture}
                                className={styles.Company}
                                sizes={Sizes.Featured}
                            />
                            : <></>
                        }
                    </div>
                    <div className={styles.SpeakerText}>
                        <h3>{forename} {surname}</h3>
                        <h3>{jobTitle}</h3>
                        <h3>{company?.data?.attributes?.title}</h3>
                        {company?.data?.attributes?.link 
                            ? <Link href={company.data.attributes.link}>Website</Link> 
                            : <p>No Website Available</p>
                        }
                        <p>{biography}</p>
                    </div>
                </div>
            </Modal>
        </>
    )
}
    