'use client'

import { Accordion, AccordionItem } from '@szhsin/react-accordion'
import { type BodyObjectView } from 'utility/utility'
import { Markdown } from 'components/shared/Markdown/Markdown'


import './AccordionStyles.scss'
import ChevronDown from 'public/icons/chevron-down.svg'

export const AccordionBlock: BodyObjectView<{blocks: {title?: string, content?: string}[]}> = ({
    blocks,
    ...context
}) =>
    <Accordion>
    {
        blocks.map(block =>
            <AccordionItem
                key={block.title ?? block.content}
                header={
                    <>
                        {block.title}
                        <ChevronDown className="chevron-down"/>
                    </>
                }
            >
                <Markdown {...context}>{block.content ?? ''}</Markdown>
            </AccordionItem>
        )
    }
    </Accordion>
