import { FC } from 'react'
import { SpeakerSummaryFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { Sizes } from 'utility/Sizes'
import { useState } from 'react'
import { SpeakerModal } from 'content-types/Speaker/Speaker.Modal/Speaker.Modal'
import { useSearchParams, useRouter, usePathname } from 'next/navigation'
import { produce } from 'immer'

import styles from './Speaker.Summary.module.scss'

export const SpeakerSummary: FC<SpeakerSummaryFragment> = ({
    ...speaker
}) => {

    const router = useRouter()
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const speakerParam = searchParams.get('speaker')

    const [ open, setOpen ] = useState<boolean>(speakerParam === `${speaker.forename}-${speaker.surname}`)

    const updateQuery = (
        value: string
    ): void => {
        const field = "speaker"
        const params = produce(Object.fromEntries(searchParams.entries()), draft => {
            if ( value === 'all' || value === searchParams.get(field) ) {
                delete draft[field]
            }
            else {
                draft[field] = value
            }
        })
        const query = new URLSearchParams([...Object.entries(params)])
        router.replace(
            `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
            { scroll: false }
        )
        value === 'all' ? setOpen(false) : setOpen(true)
    }

    return (
        <>
            <SpeakerModal open={open} updateQuery={updateQuery} {...speaker} />
            <div className={styles.Preview} onClick={ () => updateQuery(`${speaker.forename}-${speaker.surname}`) }>
                <div className={styles.Speaker}>
                    {speaker.photo ?
                        <ComponentImagePicture
                            {...speaker.photo}
                            className={styles.Image}
                            sizes={Sizes.Featured}
                        />
                        : <></>
                    }
                    <div className={styles.SpeakerText}>
                        <p>{speaker.forename} {speaker.surname}</p>
                        <p>{speaker.jobTitle}</p>
                        <p>{speaker.company?.data?.attributes?.title}</p>
                    </div>
                    <div className={styles.CompanyLogo}>
                        {speaker.company?.data?.attributes?.picture ?
                            <ComponentImagePicture
                                {...speaker.company.data.attributes.picture}
                                className={styles.Company}
                                sizes={Sizes.Featured}
                            />
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
    