import { FC } from 'react'
import { SessionFeaturedFragment } from 'generated/graphql'

import styles from './Session.Featured.module.scss'
import { SpeakerSummary } from 'content-types/Speaker/Speaker.Summary/Speaker.Summary'

export const SessionFeatured: FC<SessionFeaturedFragment> = ({
    speakers,
    name,
    theatre,
    type,
    date,
    start,
    finish
}) => 
    <div className={styles.SessionFeatured}>
        <h3>{type?.replace("_", " ")}</h3>
        <h2>{name}</h2>
        <p>{start ? start.split(":").slice(0,2).join(":") : ""} - {finish ? finish.split(":").slice(0,2).join(":") : ""}</p>
        <p>{theatre?.data?.attributes?.title}</p>
        {
            speakers === null ? <></> :
            <section className={styles.Speakers}>
                <ul data-number={speakers.data.length}>
                    {
                        speakers.data
                            .map( speaker =>
                                speaker.attributes === null || speaker.attributes === undefined ? <></> :
                                    <li key={speaker.attributes?.name}>
                                        <SpeakerSummary
                                            {...speaker.attributes}
                                        />
                                    </li>
                            )
                    }
                </ul>
            </section>
        }
        
    </div>
